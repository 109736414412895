
import { defineComponent, reactive, ref, watch } from "vue";
import { useResult } from "@vue/apollo-composable";
import Page from "@/components/Page.vue";
import gql from "graphql-tag";
import useError from "@/composables/useError";
import {
  useAddNotificationEmailMutation,
  useDeleteNotificationEmailMutation,
  useGetNotificationEmailsQuery,
  useGetSettingByKeyQuery,
  useUpdateSettingMutation,
} from "@/graphql/types";
import useToastService from "@/composables/useToastService";
import DataTable from "primevue/datatable";
import Error from "@/components/Error.vue";
import FormErrors from "@/forms/FormErrors.vue";
import useVuelidate from "@vuelidate/core";
import { email, maxValue, minValue, required } from "@vuelidate/validators";
import PrettyBar from "@/components/PrettyBar.vue";

gql`
  query GetNotificationEmails {
    notificationEmails {
      count
      items {
        ...NotificationEmailParts
      }
    }
  }
`;

gql`
  mutation AddNotificationEmail($input: AddNotificationEmailInputType!) {
    addNotificationEmail(input: $input) {
      ...NotificationEmailParts
    }
  }
`;

gql`
  mutation DeleteNotificationEmail($emailId: ID!) {
    deleteNotificationEmail(emailId: $emailId)
  }
`;

gql`
  query GetSettingByKey($key: String!) {
    setting(key: $key) {
      ...SettingParts
    }
  }
`;

gql`
  mutation UpdateSetting($input: UpdateSettingInputType!) {
    updateSetting(input: $input) {
      ...SettingParts
    }
  }
`;

export default defineComponent({
  name: "SettingsPage",
  components: { Page, DataTable, Error, FormErrors, PrettyBar },
  setup() {
    const keyAutoDisableInactiveUserDuration =
      "AutoDisableInactiveUserDuration";

    const toastService = useToastService();

    const {
      result: settingResult,
      loading: settingLoading,
      error: settingError,
    } = useGetSettingByKeyQuery({
      key: keyAutoDisableInactiveUserDuration,
    });

    const {
      result: emailResult,
      loading: emailLoading,
      error: emailError,
      refetch,
    } = useGetNotificationEmailsQuery({});

    const settingData = useResult(
      settingResult,
      null,
      (data) => (data as { setting: any }).setting
    );

    const newSetting = reactive({
      key: keyAutoDisableInactiveUserDuration,
      value: 0,
    });

    const newEmail = ref<string>("");

    const newSettingValue = ref<number>(0);

    const rules = {
      newEmail: { email },
      newSettingValue: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(1000),
      },
    };

    const v$ = useVuelidate(rules, { newEmail, newSettingValue });

    watch(
      settingData,
      (newVal) => {
        if (newVal) {
          if (newVal.value !== "") {
            newSettingValue.value = parseInt(newVal.value);
          } else {
            newSettingValue.value = 0;
          }
          newSetting.key = keyAutoDisableInactiveUserDuration;
        }
      },
      { immediate: true }
    );

    const updateSettingMutation = useUpdateSettingMutation({});

    updateSettingMutation.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Indstillinger opdateret",
        message: "Indstillinger opdateret",
      });
    });

    const onUpdateSetting = async () => {
      try {
        updateSettingMutation.mutate({
          input: {
            key: newSetting.key,
            value: newSettingValue.value.toString(),
          },
        });
      } catch (error) {
        console.error(error);
      }
    };

    const addNotificationEmailMutation = useAddNotificationEmailMutation({});

    addNotificationEmailMutation.onDone(() => {
      newEmail.value = "";
      refetch();
      toastService.show({
        severity: "success",
        title: "Add email success",
        message: "Add email success",
      });
    });

    const onAddNotificationEmail = async () => {
      try {
        addNotificationEmailMutation.mutate({
          input: {
            emailAddress: newEmail.value,
          },
        });
      } catch (error) {
        console.error(error);
      }
    };

    const deleteNotificationEmailMutation = useDeleteNotificationEmailMutation(
      {}
    );

    deleteNotificationEmailMutation.onDone(() => {
      refetch();
      toastService.show({
        severity: "success",
        title: "Delete email success",
        message: "Delete email success",
      });
    });

    const onDeleteNotificationEmail = async (data: any) => {
      try {
        deleteNotificationEmailMutation.mutate({
          emailId: data.emailId,
        });
      } catch (error) {
        console.error(error);
      }
    };

    function handleInputEmail() {
      v$.value.newEmail.$touch();
    }

    function handleInputDay() {
      v$.value.newSettingValue.$touch();
    }

    function onUpdateSettingBlur() {
      if (
        !v$.value.newSettingValue.$pending &&
        !v$.value.newSettingValue.$error
      ) {
        onUpdateSetting();
      }
    }

    return {
      settingData,
      error: useError(settingError || emailError),
      loading: settingLoading && emailLoading,
      result: settingResult,
      newSetting,
      updateSettingMutation,
      notificationEmails: useResult(emailResult),
      newEmail,
      onAddNotificationEmail,
      onDeleteNotificationEmail,
      v$,
      handleInputEmail,
      handleInputDay,
      newSettingValue,
      onUpdateSettingBlur,
    };
  },
});
